import React from 'react';

import Heading from '../components/heading';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Nav from '../components/nav';
import theme from '../theme/theme';

const IndexPage = () => (
  <Layout>
    <SEO title="404 page not found" />
    <Nav />
    <main
      css={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px 0',
      }}
    >
      <div css={{ maxWidth: '65ch' }}>
        <Heading level="1" size="900" css={{ marginBottom: 16 }}>
          404: Page not found
        </Heading>
        <p css={{ ...theme.text['600'], marginBottom: 16 }}>
          The page you are looking for cannot be found.
        </p>
      </div>
    </main>
  </Layout>
);

export default IndexPage;
